@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;500;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .app {
    @apply font-[inter]   h-screen;
  }
  /* Header */
  /* logo */
  .logo-container {
    @apply max-w-fit;
  }
  .logo {
    @apply md:text-2xl text-xl  dark:text-textDark text-textLight  cursor-pointer font-semibold;
  }
  .menu {
    @apply uppercase gap-x-6 md:flex sm:hidden items-center hidden;
  }
  .menu-mobile {
    @apply uppercase gap-x-6  flex justify-center
    dark:text-textDark text-textDark items-center md:hidden;
  }
  .menu-child {
    @apply cursor-pointer font-bold dark:text-textDark
     dark:hover:text-textLight
     hover:-translate-y-1 transition-all duration-200
      text-textDark hover:text-cyan-700;
  }

  .header-container {
    @apply dark:bg-backgroundDark flex items-center sticky
      w-[100%] top-0 z-50 p-4 h-16 bg-white shadow-lg;
  }

  .nav-container {
    @apply container mx-auto flex justify-between items-center;
  }
  .hire {
    @apply dark:bg-textDark bg-textLight  dark:text-textLight
    dark:hover:bg-white text-gray-300
    rounded hover:bg-cyan-900 py-1 px-3 text-sm md:px-6  font-bold
    outline-none focus:outline-none;
  }

  /* Me */
  .me-container {
    @apply md:flex-row flex flex-col-reverse p-4 text-indigo-500 
       dark:text-gray-300 container mx-auto;
  }
  .me-child1 {
    @apply basis-1/2 flex h-screen  flex-col space-y-4 py-6 font-bold text-4xl md:text-6xl;
  }
  h1 {
    @apply text-center text-cyan-800 dark:text-gray-300 md:text-start;
  }
  p {
    @apply text-center md:text-start;
  }
  .me-child2 {
    @apply basis-1/2 flex  md:py-2 justify-end md:mt-0 mt-10;
  }
  /* skill */
  .skill-container {
    @apply h-screen flex mt-8  relative items-center  justify-center;
  }
  .skill-wrapper {
    @apply relative mx-auto dark:bg-white/30 
    rounded-md shadow-lg
    md:w-[80%] md:h-[70%] w-[90%] h-[70%] z-40 backdrop-blur-3xl flex
    md:flex-row flex-col overflow-hidden
    bg-textLight/30;
  }

  .skill-light {
    @apply md:w-[70%] rounded-md  md:h-[50%]
     bg-sky-500 blur-2xl absolute
      top-72 md:top-1/4
     h-[40%] w-[40%];
  }
  .skill-category {
    @apply md:h-full rounded-md md:w-[20%] w-[100%]
    flex md:flex-col flex-row  md:py-5 md:p-0  p-3
    mx-auto md:justify-center justify-center gap-y-2
    dark:text-textDark text-textLight md:gap-y-16
    md:text-xl sm:text-lg text-sm;
  }
  #link {
    @apply md:p-8 px-2 py-3     transition-all duration-300 ease-in-out
    md:rounded-b-none rounded-b-xl  md:rounded-r-xl;
  }
  .skill-active {
    @apply dark:text-textLight shadow-xl dark:bg-white/20
    text-textDark bg-textLight;
  }
  .skill-content {
    @apply rounded-md h-full overflow-y-scroll scrollbar-hide md:w-[100%] w-full;
  }

  .contact-wrapper {
    @apply relative mx-auto dark:bg-white/30 
    rounded-md shadow-lg
    md:w-[80%] md:h-[70%] w-[90%]  z-40 backdrop-blur-3xl flex
    md:flex-row flex-col overflow-hidden
    bg-textLight/30;
  }
  .contact-input {
    @apply w-[80%] bg-transparent dark:text-textDark text-textDark 
     outline-none border-b-2 py-1 border-white/30 focus:border-b-white;
  }

  .degree-wrapper {
    @apply relative mx-auto p-5  dark:bg-white/30 
    rounded-md shadow-lg
    md:w-[80%] md:h-[80%] w-[90%] h-[50%] z-40 backdrop-blur-3xl flex
    md:flex-row flex-col overflow-hidden
    bg-textLight/30 gap-y-4;
  }
  .degree-cart{
    @apply relative rounded-md  md:h-full h-full overflow-x-hidden w-full md:ml-4 scrollbar-hide object-fill
  }
  .title-degree{
    @apply absolute -right-24 transition-all duration-700
    group-hover:-translate-x-28  top-10 px-4 py-1 bg-textLight rounded-md text-white
  }

}

@layer utilities {
  .active {
    @apply before:absolute before:rounded-lg before:bg-backgroundDark
       before:dark:bg-backgroundLight before:h-1 
      before:-bottom-1 transition-all duration-500 transform before:-left-10 before:translate-x-10 before:w-full;
  }
}
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}
